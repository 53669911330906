// src/utils/auth.js

// Check if the user is authenticated based on a token in localStorage
export const isAuthenticated = () => {
  return !!localStorage.getItem('authToken');
};

// Set an auth token in localStorage to indicate that the user is authenticated
export const setAuthToken = (token = 'dummyToken') => {
  localStorage.setItem('authToken', token);
};

// Clear the auth token from localStorage to log the user out
export const clearAuthToken = () => {
  localStorage.removeItem('authToken');
};

export const getSchoolNumber = () => {
  return localStorage.getItem('school_number');
};

export const setSchoolNumber = (school_number) => {
  localStorage.setItem('school_number', school_number);
};

export const removeSchoolNumber = () => {
  localStorage.removeItem('school_number');
};