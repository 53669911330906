import React from 'react';
import ReactDOM from 'react-dom/client'; // Note the change here
import './index.css';
import App from './components/App';

console.log('env index:', process.env.REACT_APP_API_URL);

const rootElement = document.getElementById('root');

// Create a root.
const root = ReactDOM.createRoot(rootElement);

// Initial render: Render the <App> component to the root.
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);