import React, { useState, useEffect } from 'react';

function formatTimestamp(timestamp, time_token) {
    // if timestamp is not None
    if (timestamp === undefined) {
        return 'Time taken: ' + time_token.total + ' seconds';
    } else {
        const date = new Date(timestamp * 1000); // Convert seconds to milliseconds
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true // Use 12-hour format
        };
        return date.toLocaleString('en-US', options); // Adjust locale as needed
    }
}

function dateToISOButLocal(date) {
    return date.toLocaleString('sv', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }).replace(' ', 'T');
}

function StudentData({ students }) {
    // start time should 1 day before the current time
    const start_time_now = dateToISOButLocal(new Date(Date.now() - 86400000));
    const end_time_now = dateToISOButLocal(new Date());

    const [expandedStudents, setExpandedStudents] = useState({});
    const [filteredStudents, setFilteredStudents] = useState(students);
    const [filters, setFilters] = useState({ startDateTime: start_time_now, endDateTime: end_time_now });

    const toggleExpand = (index) => {
        setExpandedStudents(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    const applyFilters = () => {
        let { startDateTime, endDateTime } = filters;

        // If startDateTime is selected without a time, default to the start of the day
        if (startDateTime && !startDateTime.includes('T')) {
            startDateTime += 'T00:00'; // Append time 'T00:00' for start of the day
        }

        // If endDateTime is selected without a time, default to the end of the day
        if (endDateTime && !endDateTime.includes('T')) {
            endDateTime += 'T23:59'; // Append time 'T23:59' for end of the day
        }

        // Handle case where startDateTime or endDateTime is an empty string
        if (!startDateTime || !endDateTime) {
            alert('Please select both date and time.');
            return;
        }

        const currentDateTime = dateToISOButLocal(new Date()) // Get current date/time in "yyyy-MM-ddTHH:mm" format

        // Prevent filtering if startDateTime or endDateTime is in the future
        if (startDateTime > currentDateTime || endDateTime > currentDateTime) {
            alert('Please select a date and time within the current date and time.');
            return;
        }

        const filteredData = students.map(student => {
            let startIndex = -1;
            let endIndex = student.messages.length;

            // Find the index of the first user message within the time range
            for (let i = 0; i < student.messages.length; i++) {
                const message = student.messages[i];
                if (message.role === 'user' && message.timestamp) {
                    const messageTimestamp = new Date(message.timestamp * 1000);
                    if (messageTimestamp >= new Date(startDateTime)) {
                        startIndex = i;
                        break;
                    }
                }
            }

            // Find the index of the last user message within the time range
            for (let i = student.messages.length - 1; i >= 0; i--) {
                const message = student.messages[i];
                if (message.role === 'user' && message.timestamp) {
                    const messageTimestamp = new Date(message.timestamp * 1000);
                    if (messageTimestamp <= new Date(endDateTime)) {
                        endIndex = i + 1; // endIndex is exclusive in slice, and also pick the `bot` message after the last user message.
                        break;
                    }
                }
            }

            // If `startIndex` is not -1, then increase `endIndex` by 1 to include the last bot message.
            if (startIndex !== -1) {
                endIndex += 1;
            }

            // Slice the messages array based on startIndex and endIndex
            const filteredMessages = student.messages.slice(startIndex, endIndex);

            return {
                ...student,
                messages: filteredMessages
            };
        });

        setFilteredStudents(filteredData);
    };

    useEffect(() => {
        if (window.MathJax) {
            window.MathJax.typesetPromise()
                .catch(err => console.error('MathJax rendering error:', err));
        }
    }, [filteredStudents, expandedStudents]); // Trigger whenever messages update    

    return (
        <div>
            <div>
                <label>Filter by Date and Time:</label>
                <input
                    type="datetime-local"
                    id="startDateTime"
                    name="startDateTime"
                    value={filters.startDateTime || ''}
                    onChange={(e) => setFilters({ ...filters, startDateTime: e.target.value })}
                    max={end_time_now} // Set max date/time to current date/time
                />
                <input
                    type="datetime-local"
                    id="endDateTime"
                    name="endDateTime"
                    value={filters.endDateTime || ''}
                    onChange={(e) => setFilters({ ...filters, endDateTime: e.target.value })}
                    max={end_time_now} // Set max date/time to current date/time
                />
                <button onClick={applyFilters}>Apply Filters</button>
            </div>

            <table>
                <thead>
                    <tr>
                        <th>Student Name</th>
                        <th>Student Number</th>
                        <th>Chat History</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredStudents.map((student, index) => (
                        <tr key={index}>
                            <td>{student.student_name}</td>
                            <td>{student.student_number}</td>
                            <td>
                                <ul style={{ listStyleType: 'none', padding: 0 }}>
                                    {student.messages.slice(0, expandedStudents[index] ? undefined : 3).map((message, idx) => (
                                        <li key={idx} style={{ textAlign: message.role === 'user' ? 'right' : 'left', marginBottom: '10px' }}>
                                            <div style={{ border: '1px solid #ccc', borderRadius: '5px', padding: '8px', maxWidth: '70%', display: 'inline-block', backgroundColor: message.role === 'user' ? '#e0f7fa' : '#f5f5f5' }}>
                                                <strong>{message.role === 'user' ? student.student_name : 'Bot'}</strong><br />
                                                <div
                                                    style={{ whiteSpace: 'pre-wrap', fontFamily: 'system-ui, -apple-system, Inter, sans-serif' }}
                                                    dangerouslySetInnerHTML={{ __html: message.message }}
                                                /><br />
                                                <small>{formatTimestamp(message.timestamp, message.time_token)}</small>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                                {student.messages.length > 3 && (
                                    <button onClick={() => toggleExpand(index)}>
                                        {expandedStudents[index] ? 'Show Less' : 'Show More'}
                                    </button>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default StudentData;
