import React, { useState } from 'react';
import { api } from '../api/api'
// import { CosmosClient } from '@azure/cosmos';
import "./App.css";

const Login = ({ setAuthenticated , setSchoolNumber}) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');
    try {
      const response = api.post('/api/auth/login', { username, password });

      if ((await response).status === 200) {
        const data = (await response).data;
        if (data.success) {
          localStorage.setItem('authToken', data.token);
          setAuthenticated(true);
          setSchoolNumber(username);
        } else {
            setError('Incorrect username or password.\nPlease try again.');
        }
      } else {
        const errorData = (await response).data;
        setError(errorData.message || 'Login failed');
      }
    } catch (error) {
      setError('Login failed. Please try again later.' + error);
    }
  };

  return (
    <div className="login-container">
      <h1><strong>Student Chat History Portal</strong></h1>
      <br></br>
      <h2>Login</h2>
      <br></br>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Username"
          required
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          required
        />
        <button type="submit">Login</button>
        {error && <p className="error">{error}</p>}
      </form>
    </div>
  );
};

export default Login;