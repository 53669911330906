import React from 'react';
import './App.css';

function Filters({ onClassNumberFilterChange }) {
    const handleClassNumberFilterChange = (e) => {
        onClassNumberFilterChange(e.target.value);
    };

    return (
        <div className="class-number-filters">
            <label>Filter by Class Number:</label>
            <input type="text" onChange={handleClassNumberFilterChange} />
            
        </div>
    );
}

export default Filters;
