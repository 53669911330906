import React, { useState } from 'react';
import Login from './Login';
import Filters from './Filters';
import StudentData from './StudentData';
import './App.css';
import { api } from '../api/api';
import { isAuthenticated, setAuthToken, clearAuthToken, getSchoolNumber, setSchoolNumber, removeSchoolNumber } from '../utils/auth';
import { Spinner } from './Spinner';  // Import Spinner component

function App() {
    const [authenticated, setAuthenticated] = useState(isAuthenticated());
    const [school_number, setTempSchoolNumber] = useState(getSchoolNumber());
    const [class_number, setClassNumberFilter] = useState('');
    const [students, setStudents] = useState([]);
    const [isLoading, setIsLoading] = useState(false);  // Loading state
    const [fetchCompleted, setFetchCompleted] = useState(false);  // Fetch completed state

    const handleClassNumberFilterChange = (newFilter) => {
        setClassNumberFilter(newFilter);
    };

    const handleFetchStudents = async () => {
        setIsLoading(true);
        setFetchCompleted(false);
        try {
            const response = api.post('/api/students', { school_number, class_number });
            const data = (await response).data;
            if (data["error"]) {
                console.error('Error fetching student data (DB error):', data["error"]);
                return;
            }
            setStudents(data);
            setFetchCompleted(true);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching student data (Other error):', error);
        } finally {
            // setIsLoading(false);
        }
    };

    const handleLogout = () => {
        setAuthenticated(false);
        clearAuthToken();
    };

    return (
        <div className="App">
            {authenticated ? (
                <h1> Welcome, {school_number} </h1>
            ) : null}
            {!authenticated ? (
                <Login
                    setAuthenticated={(authState) => {
                        setAuthenticated(authState);
                        if (authState) {
                            setAuthToken('token321');
                        } else {
                            clearAuthToken();
                        }
                    }}
                    setSchoolNumber={(school_number) => {
                        setTempSchoolNumber(school_number);
                        if (school_number) {
                            setSchoolNumber(school_number);
                        } else {
                            removeSchoolNumber();
                        }
                    }}
                />
            ) : (
                <>
                    <div className="logout-container">
                        <button onClick={handleLogout}>Logout</button>
                    </div>
                    <Filters onClassNumberFilterChange={handleClassNumberFilterChange} />
                    <div className="fetch-container">
                        <button onClick={handleFetchStudents} disabled={isLoading}>
                            Fetch Students
                        </button>
                        {isLoading && <Spinner />} {/* Show spinner while loading */}
                        {fetchCompleted && !isLoading && <span className="checkmark">✔</span>} {/* Show checkmark on success */}
                    </div>
                    <StudentData students={students} />
                </>
            )}
        </div>
    );
}

export default App;
